import React, { useContext } from "react";
import { Link } from "gatsby";

import GlobalContext from "../../context/GlobalContext";
import imgC from "../../assets/image/antigenos-diagrama.png";

const CTA = () => {
  const gContext = useContext(GlobalContext);

  return (
    <div className="pt-3 pt-md-7 pt-lg-15 pb-15 pb-md-18 pb-lg-25 mb-lg-1">
      <div className="container">
        <div className="row align-items-center justify-content-center">
          <div
            className="col-xl-9 col-lg-9 col-md-10 col-sm-11"
            data-aos="fade-right"
            data-aos-duration={500}
          >
            <div
              className="bg-images d-flex align-items-center justify-content-center max-w-920 py-20 py-lg-34 py-sm-28 rounded-11"
              css={`
                background-image: url(${imgC});
              `}
            >
              {/* <a
                href="/#"
                className="video-btn sonar bg-blue-3-op5 text-white circle-98 font-size-8"
                onClick={(e) => {
                  e.preventDefault();
                  gContext.toggleVideoModal();
                }}
              >
                <i className="fa fa-play" />
              </a> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-10 col-sm-11">
            <div className="pt-9 pt-lg-0 pr-xs-10 pr-sm-5 pr-md-25 pr-lg-0 pl-xl-16">
              <h2
                className="font-size-10 mb-8 letter-spacing-n83"
                data-aos="fade-up"
                data-aos-duration={600}
              >
                Cotiza con nosotros
              </h2>
              <p
                className="font-size-6 mb-0 pr-xs-15 pr-sm-10 pr-xl-15"
                data-aos="fade-up"
                data-aos-duration={900}
              >
                Contáctanos para cotizar y responderemos a la brevedad.{" "}
              </p>
              <div
                className="mt-11"
                data-aos="fade-up"
                data-aos-duration={1200}
              >
                <Link
                  to="/contacto"
                  className="btn btn-blue-3 btn-2 font-weight-medium rounded-5"
                >
                  Contáctanos
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA;
