import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/empresas/Hero";
import CTA from "../sections/empresas/CTA";
import Features from "../sections/empresas/Antigenos";
import Jobs from "../sections/empresas/Jobs";
import PCR from "../sections/empresas/PCR";

const PricingPage = () => {
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          footerStyle: "style2",
        }}
      >
        <Hero />
        <Features />
        <PCR />
        <CTA />
        {/* <Jobs /> */}
      </PageWrapper>
    </>
  );
};
export default PricingPage;
